import Modal from "components/utils/Modal/Modal";
import React from "react";
import "./VerifyPhoneNumberModal.scss";
import Cookies from "js-cookie";
import { NCTO_CARD_ACTIVATION_PAYLOAD } from "components/constants";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { SmallLoader, TinyLoad } from "components/utils/SmallLoader";
import { API } from "lib/api";
import {
	resendCode,
	verifyPhoneNumber,
} from "lib/api/apiRoutesConfig/services/NCTOService";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { useHistory } from "react-router-dom";

export default function VerifyPhoneNumberModal({ show }) {
	const [verificationCode, setVerificationCode] = React.useState("");
	const sessionPayload = Cookies.get(NCTO_CARD_ACTIVATION_PAYLOAD);
	const [currentState, setCurrentState] = React.useState(IDLE);
	const [isResending, setIsResending] = React.useState(false);
	const [countDownTime, setCountDownTime] = React.useState(60);
	const history = useHistory();

	const { sessionId, maskedPhoneNumber } =
		JSON.parse(sessionPayload || "{}") || {};

	async function onResendCode() {
		setIsResending(true);
		try {
			const res = await API.put(resendCode(sessionId));
			const { status } = res || {};
			if (status === 200) {
				setCountDownTime(60);
				ToastsStore.success(
					"OTP resent successfully",
					6000,
					"right-toast"
				);
			}
		} catch (error) {
			ToastsStore.error(getErrorMessage(error), 6000, "right-toast");
		} finally {
			setIsResending(false);
		}
	}

	async function onVerifyPhoneNumber() {
		setCurrentState(LOADING);
		try {
			const res = await API.put(verifyPhoneNumber(sessionId), null, {
				params: {
					verificationCode,
				},
			});
			const { status } = res || {};
			if (status === 200) {
				setCurrentState(FINISHED);
				history.replace("/ncto-card-activation/activate-card");
			}
		} catch (error) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(error), 6000, "right-toast");
		}
	}

	React.useEffect(() => {
		if (countDownTime === 0) return;
		const countDown = setTimeout(
			() => setCountDownTime(countDownTime - 1),
			1000
		);
		return () => clearTimeout(countDown);
	}, [countDownTime]);

	return (
		<Modal show={show}>
			<div className="ntco-verify-phone-number-modal">
				<h2>Enter OTP</h2>
				<p className="ntco-verify-phone-number-modal__leading">
					{`Your OTP has been sent to ${maskedPhoneNumber}`}
				</p>
				<div className="ntco-verify-phone-number-modal__otp">
					<CustomPinInput
						title="Enter your 6 digit OTP"
						length={6}
						onChange={(value) => {
							setVerificationCode(value);
						}}
					/>
				</div>
				<button
					className="btn btn-mint-default"
					disabled={
						currentState === LOADING || verificationCode.length < 6
					}
					onClick={onVerifyPhoneNumber}
				>
					{currentState === LOADING ? <SmallLoader /> : "Continue"}
				</button>
				<p className="ntco-verify-phone-number-modal__footer-text">
					Didn’t receive the code?{" "}
					<button
						className="btn back-btn"
						disabled={
							countDownTime > 0 ||
							currentState === FINISHED ||
							currentState === LOADING ||
							isResending
						}
						onClick={onResendCode}
					>
						{isResending ? <TinyLoad /> : "Resend OTP"}{" "}
						{countDownTime > 0 &&
							currentState !== FINISHED &&
							`in ${countDownTime}s`}
					</button>
				</p>
			</div>
		</Modal>
	);
}
