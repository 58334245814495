import { NCTO_CARD_ACTIVATION_PAYLOAD } from "components/constants";
import Cookies from "js-cookie";
import React from "react";
import { Redirect, Link } from "react-router-dom";
import HeaderImage from "assets/img/ncto-activation-step-2.png";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { SmallLoader } from "components/utils/SmallLoader";
import { API } from "lib/api";
import { confirmPhoneNumber } from "lib/api/apiRoutesConfig/services/NCTOService";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import VerifyPhoneNumberModal from "./VerifyPhoneNumberModal";

export default function ConfirmPhoneNumber() {
	const sessionPayload = Cookies.get(NCTO_CARD_ACTIVATION_PAYLOAD);
	const [currentState, setCurrentState] = React.useState(IDLE);
	const [missingPhoneNumbers, setMissingPhoneNumber] = React.useState("");
	const [showVerificationModal, setShowVerificationModal] =
		React.useState(false);

	const { sessionId, maskedPhoneNumber } =
		JSON.parse(sessionPayload || "{}") || {};

	const splitMaskedPhoneNumber = React.useMemo(() => {
		if (!maskedPhoneNumber) return;
		return maskedPhoneNumber.split("*").filter((value) => value);
	}, [maskedPhoneNumber]);

	async function onConfirmPhoneNumber() {
		setCurrentState(LOADING);
		try {
			const res = await API.put(confirmPhoneNumber(sessionId), null, {
				params: {
					missingPhoneNumbers,
				},
			});
			const { status } = res || {};
			if (status === 200) {
				setCurrentState(FINISHED);
				setShowVerificationModal(true);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	}

	if (!sessionId || !maskedPhoneNumber)
		return <Redirect to="/ncto-card-activation" />;

	return (
		<>
			<div className="ncto-main-content">
				<img
					alt="NCTO Card Activation"
					width={400}
					height={400}
					src={HeaderImage}
					className="ncto-header-img"
				/>

				<div className="ncto-page-header">
					<h1>Phone number verification</h1>
					<p>
						{`Kindly fill in the four missing numbers associated with your
					phone number ${maskedPhoneNumber}. An OTP will be sent to the
					phone number provided`}
					</p>
				</div>

				<div className="ncto-confirm-phone-form">
					<span>{splitMaskedPhoneNumber[0]}</span>
					<CustomPinInput
						length={4}
						onChange={(value) => {
							setMissingPhoneNumber(value);
						}}
					/>
					<span>{splitMaskedPhoneNumber[1]}</span>
				</div>

				<button
					className="btn btn-mint-default"
					disabled={
						currentState === LOADING ||
						missingPhoneNumbers.length < 4
					}
					onClick={onConfirmPhoneNumber}
				>
					{currentState === LOADING ? <SmallLoader /> : "Continue"}
				</button>

				<Link
					className="ncto-home-button"
					to="/ncto-card-activation"
					replace
				>
					Return to Home
				</Link>
			</div>

			<VerifyPhoneNumberModal show={showVerificationModal} />
		</>
	);
}
