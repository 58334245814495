import React from "react";
import HeaderImage from "assets/img/ncto-activation-step-1.png";
import { Formik } from "formik";
import * as yup from "yup";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { ToastsStore } from "react-toasts";
import { getErrorMessage, removeCookieValues } from "components/utils/helpers";
import { API } from "lib/api";
import { validateCardInfo } from "lib/api/apiRoutesConfig/services/NCTOService";
import Cookies from "js-cookie";
import { NCTO_CARD_ACTIVATION_PAYLOAD } from "components/constants";
import { SmallLoader } from "components/utils/SmallLoader";
import { useHistory } from "react-router-dom";

const formValidationSchema = yup.object().shape({
	accountNumber: yup
		.string()
		.trim()
		.required("Account number is required")
		.min(10, "Account number is required")
		.max(10, "Only enter 10 digits"),
	card6Digits: yup
		.string()
		.trim()
		.required("Last 6 digits are required")
		.min(6, "Last 6 digits are required")
		.max(6, "Only enter 6 digits"),
});

export default function InitialVerificationStep() {
	const isDev = process.env.REACT_APP_STAGE === "dev";
	const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
	const [currentState, setCurrentState] = React.useState(IDLE);

	const [recaptchaToken, setRecaptchaToken] = React.useState("");
	const [intervalId, setIntervalId] = React.useState(0);
	const history = useHistory();

	const initialValues = {
		accountNumber: "",
		card6Digits: "",
	};

	const handleOnLoaded = () => {
		window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(recaptchaKey, { action: "" })
				.then((token) => {
					setRecaptchaToken(token);
				});
		});
	};

	async function onValidateCardInfo(values) {
		setCurrentState(LOADING);
		try {
			const res = await API.post(validateCardInfo, {
				...values,
				googleToken: !isDev ? recaptchaToken : "recaptchaToken",
			});
			const { status, data } = res || {};
			if (status === 200) {
				setCurrentState(FINISHED);
				Cookies.set(
					NCTO_CARD_ACTIVATION_PAYLOAD,
					JSON.stringify(data.data)
				);
				history.push("/ncto-card-activation/confirm-phone-number");
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	}

	React.useEffect(() => {
		removeCookieValues();
		const script = document.createElement("script");
		document
			.querySelector(".grecaptcha-badge")
			?.classList.remove("hide-captcha");
		if (!isDev) {
			script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
			script.addEventListener("load", handleOnLoaded);
			document.body.appendChild(script);
			const intervalId = setInterval(() => {
				this.handleOnLoaded();
			}, 90 * 1000);
			setIntervalId(intervalId);
		}

		return () => {
			script.remove();
			document
				.querySelector(".grecaptcha-badge")
				?.classList.add("hide-captcha");
			clearInterval(intervalId);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="ncto-main-content">
			<img
				alt="NCTO Card Activation"
				width={400}
				height={400}
				src={HeaderImage}
				className="ncto-header-img"
			/>

			<div className="ncto-page-header">
				<h1>NCTO Card Activation</h1>
				<p>Kindly fill out the form below to process your NTCO card.</p>
			</div>

			<Formik
				initialValues={initialValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => onValidateCardInfo(values)}
			>
				{({
					handleSubmit,
					values,
					errors,
					touched,
					handleBlur,
					setFieldValue,
				}) => (
					<form
						className="ncto-form"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<FormInputGroupV2
							id="account-number"
							label="Account number"
							name="accountNumber"
							maxLength={10}
							placeholder="Enter account number"
							onBlur={handleBlur}
							value={values.accountNumber}
							onChange={(e) => {
								let value = e.target.value;
								value = value.replace(/[^0-9]/g, "");
								setFieldValue("accountNumber", value);
							}}
							error={
								touched.accountNumber && errors.accountNumber
							}
						/>

						<FormInputGroupV2
							id="card6Digits"
							label="Last 6 digits of ATM Card"
							name="card6Digits"
							maxLength={6}
							placeholder="Enter last 6 digits of ATM Card"
							onBlur={handleBlur}
							value={values.card6Digits}
							onChange={(e) => {
								let value = e.target.value;
								value = value.replace(/[^0-9]/g, "");
								setFieldValue("card6Digits", value);
							}}
							error={touched.card6Digits && errors.card6Digits}
						/>

						<button
							className="btn btn-mint-default"
							type="submit"
							disabled={currentState === LOADING}
						>
							{currentState === LOADING ? (
								<SmallLoader />
							) : (
								"Continue"
							)}
						</button>
					</form>
				)}
			</Formik>
		</div>
	);
}
