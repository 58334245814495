import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import { updateLoginDetails } from "../../../../actions/loginActions";
import Alert from "../../../utils/Alert";
import ForgotPassword from "../../ForgotPassword/ForgotPassword";
import { SmallLoader } from "../../../utils/SmallLoader";
import ValidateForm from "../../../utils/Validator";
import { getErrorMessage } from "../../../utils/helpers";
import { API } from "../../../../lib/api";
import loginEndpoint from "../../../../lib/api/apiRoutesConfig/services/AccountMS/login/web";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";
import PhoneNumberInputV2 from "components/utils/PhoneNumberInput/PhoneNumberInputV2";

class Login extends Component {
	signal = Axios.CancelToken.source();
	_isMounted = false;
	script = document.createElement("script");
	isDev = process.env.REACT_APP_STAGE === "dev";
	recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

	state = {
		loading: false,
		request: {
			username: "",
			password: "",
			accountType: "INDIVIDUAL",
		},
		alert: {
			alert_newkey: 1,
		},
		passwordRead: true,
		errors: {},
		recaptchaToken: "",
		intervalId: 0,
	};

	componentDidMount = () => {
		// eslint-disable-next-line no-unused-expressions
		document
			.querySelector(".grecaptcha-badge")
			?.classList.remove("hide-captcha");

		if (!this.isDev) {
			this.script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaKey}`;
			this.script.addEventListener("load", this.handleOnLoaded);
			document.body.appendChild(this.script);
			const intervalId = setInterval(() => {
				this.handleOnLoaded();
			}, 90 * 1000);
			this.setState((prevState) => ({
				...prevState,
				intervalId,
			}));
		}

		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this.script.remove();
		// eslint-disable-next-line no-unused-expressions
		document
			.querySelector(".grecaptcha-badge")
			?.classList.add("hide-captcha");
		this.signal.cancel("canceling network calls");
		clearInterval(this.state.intervalId);
		this._isMounted = false;
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const { message, success } = nextProps.location || {};
		if (message !== prevState.tempMessage) {
			return {
				tempMessage: message,
				alert: {
					alert_newkey: prevState.alert.alert_newkey + 1,
					alert_show: "show",
					alert_message: message,
					alert_type: success ? "success" : "danger",
				},
			};
		}
		return null;
	}

	handleOnLoaded = () => {
		window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(this.recaptchaKey, {
					action: "",
				})
				.then((token) => {
					this.setState((prevState) => ({
						...prevState,
						recaptchaToken: token,
					}));
				});
		});
	};

	handlePhone = (__, value, countryData) => {
		let Numbers = value.replace(/[^0-9]/g, "");
		value = Numbers.replace(/^(-?)0+/, "");
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				request: {
					...prevState.request,
					username: value,
				},
				errors: {
					...prevState.errors,
					username: false,
				},
				dialCode: countryData.dialCode,
			}));
	};

	handleInput = (e) => {
		const { name, value } = e.target;
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				errors: {
					...prevState.errors,
					[name]: false,
				},
				request: {
					...prevState.request,
					[name]: value,
				},
			}));
	};

	togglePassword = (e) => {
		e.preventDefault();
		this.props.toggleRest();
	};

	setError = (errMsg) => {
		this._isMounted &&
			this.setState((prevState) => ({
				loading: false,
				alert: {
					alert_newkey: prevState.alert.alert_newkey + 1,
					alert_show: "show",
					alert_message: errMsg,
					alert_type: "danger",
				},
			}));
	};

	logginAction = async () => {
		try {
			this._isMounted && this.setState({ loading: true });
			const { request, dialCode, recaptchaToken } = this.state;
			const { updateLoginDetails, history } = this.props || {};
			const requestPayload = { ...request };
			requestPayload.username = "+" + dialCode + request.username;
			let res = await API.post(
				loginEndpoint,
				{
					...requestPayload,
					recaptchaToken,
				},
				{
					cancelToken: this.signal.token,
				}
			);
			if (res.status === 200) {
				updateLoginDetails(res.data.data);
				history.replace("/sign-in/confirm-OTP");
			}
		} catch (err) {
			const error = getErrorMessage(err);
			this.setState((prevState) => ({
				request: {
					...prevState.request,
					password: "",
				},
			}));
			this.setError(error);
		}
	};

	submitForm = (e) => {
		e.preventDefault();
		const { request } = this.state;
		const { errors, formisValid } = ValidateForm(e, request) || {};
		formisValid ? (() => this.logginAction())() : this.setState({ errors });
	};

	render() {
		const {
			alert,
			loading,
			request,
			errors,
			passwordRead,
			recaptchaToken,
		} = this.state;
		const { alert_show, alert_message, alert_newkey, alert_type } = alert;
		const { username, password } = request;
		const { toggleforgot } = this.props;

		return (
			<section>
				<Helmet>
					<title>
						{`${process.env.REACT_APP_NAME} - ${
							toggleforgot ? "Forgot Password" : "Sign In"
						}`}
					</title>
				</Helmet>
				<div>
					<Alert
						key={alert_newkey}
						type={alert_type}
						message={alert_message}
						show={alert_show}
					/>
					<br />
					{toggleforgot ? (
						<ForgotPassword
							history={this.props.history}
							goBack={this.props.toggleRest}
						/>
					) : (
						<form onSubmit={this.submitForm}>
							<div className="login-form">
								<PhoneNumberInputV2
									label="Phone Number"
									fieldId="username"
									inputFieldName="username"
									inputValue={username}
									handlePhoneNumberChange={this.handlePhone}
									error={!!errors["username"]}
									isDisabled={loading}
									onSelectFlag={() =>
										this.setState((prevState) => ({
											...prevState,
											request: {
												...prevState.request,
												username: "",
											},
										}))
									}
								/>

								<FormInputGroupV2
									type="password"
									label="Password"
									name="password"
									onChange={this.handleInput}
									onFocus={() =>
										this.setState({
											passwordRead: false,
										})
									}
									value={password}
									autoComplete="off"
									maxLength={50}
									readOnly={passwordRead}
									error={!!errors["password"]}
								/>

								<div className="text-right">
									<a
										href="about:blank"
										className="btn-text-gold"
										onClick={this.togglePassword}
									>
										Forgot Password?
									</a>
								</div>

								<button
									className="btn-primary w-100"
									disabled={
										loading ||
										(!recaptchaToken && !this.isDev)
									}
								>
									{loading ? <SmallLoader /> : "Login"}
								</button>

								<div className="form-footer">
									Having issues?{" "}
									<a
										className="btn-text-primary"
										href="https://mintyn.freshdesk.com/support/home"
										rel="noopener noreferrer"
										target="_blank"
									>
										Contact Support
									</a>{" "}
									or{" "}
									<Link
										to="/reset-security-details"
										className="btn-text-primary"
									>
										Proceed to Reset Security Details
									</Link>
								</div>
							</div>
						</form>
					)}
				</div>
			</section>
		);
	}
}

export default connect((state) => ({ state }), {
	updateLoginDetails,
})(withRouter(Login));
